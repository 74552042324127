import { render, staticRenderFns } from "./comp_dpc-VideoBackground.vue?vue&type=template&id=0421a0f8&scoped=true"
import script from "./comp_dpc-VideoBackground.vue?vue&type=script&lang=js"
export * from "./comp_dpc-VideoBackground.vue?vue&type=script&lang=js"
import style0 from "./comp_dpc-VideoBackground.vue?vue&type=style&index=0&id=0421a0f8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0421a0f8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer})


/* vuetify-loader */
import installDirectives from "!../../../../../node_modules/vuetify-loader/lib/runtime/installDirectives.js"
import Intersect from 'vuetify/lib/directives/intersect'
installDirectives(component, {Intersect})
